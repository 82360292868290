@import '_mixin';
// Vars

$imgPath: '/assets/img';
$containerWidth: 375px;

$active: 'body.no-touch &:not(.active):hover, &:not(.active):active';
$activeAndTouch: '&:hover, &:active';

$xxs: 'only screen and (max-width : 374px)';
$xs: 'only screen and (max-width : 575px)';
$sm: 'only screen and (min-width : 576px) and (max-width: 767px)';
$md: 'only screen and (min-width : 768px) and (max-width: 991px)';
$lg: 'only screen and (min-width : 992px) and (max-width: 1199px)';
$xl: 'only screen and (min-width : 1200px)';
$xxl: 'only screen and (min-width : 1500px)';

$smOrLess: 'only screen and (max-width: 767px)';
$mdOrLess: 'only screen and (max-width: 991px)';

$smOrMore: 'only screen and (min-width: 576px)';
$mdOrMore: 'only screen and (min-width: 768px)';
$lgOrMore: 'only screen and (min-width: 992px)';
$xlOrMore: 'only screen and (min-width: 1200px)';
$xxlOrMore: 'only screen and (min-width: 1500px)';

$smOrMd: 'only screen and (min-width : 576px) and (max-width: 991px)';
$mdOrLg: 'only screen and (min-width : 768px) and (max-width: 1199px)';

// Brand colors
$brandPurple: #72035d;
$brandPurpleRgb: 114, 3, 93;
$supportPurple: #560246;
$brandSalmon: #fa837f;
$digitalBlue: #2674d9;

// Support brand colors
$lavender: #e8b3f2;
$brandPink: #ffe8e8;
$lightBlue: #cce2ff;
$brandBlue: #488ee8;

// Neutrals
$brandGrey: #f5f3f3;
$brandGreyRgb: 245, 243, 243;
$neutral200: #f1eaea;
$neutral300: #e2d4d4;

// Greyscale, white, black
$white: #fff;
$grey200: #a3a3a3;
$grey400: #757575;
$grey700: #5f5f5f;
$black: #1a1a1a;

// Sentiment (feedback) colors
$green500: #87b738;
$red1000: #d83030;
$orange500: #ff9000;

// Miscellaneous colors
$colorSoftMagenta: #efb9f8;
$colorYellow: #ffbd00;
$colorBorderGrey: #d6d6d6;
$colorOuraBlue: #2f4a73;
$colorUseProtectionPink: #ff736f;

// ab-312
$colorShimmerGradient: linear-gradient(
  259.07deg,
  #ffb9f9 4.15%,
  #ffccfa 15.43%,
  #fff5f5 47.14%,
  #cce2ff 85.08%,
  #bad9ff 96.5%
);

// New colors currently not used but as preparation for the new brand refresh
$ncPurple500: #72035d;
$ncPurple700: #3c0231;
$ncPurple600: #560246;
$ncPurple100: #f8f2f7;

$lavender500: #f796ee;
$lavender600: #f471e8;
$lavender400: #fdabf5;
$lavender300: #ffcbff;

$neueBlue500: #3c6df0;
$neueBlue400: #709dff;
$neueBlue300: #9cbbff;
$neueBlue100: #e9f3ff;

$raspberry500: #d71c5a;
$raspberry400: #ed7f9d;
$raspberry300: #febfcf;

$ink: #f8f2f7;
$fadedInk: #5f5f5f;

:root {
  /**
  * Used by NCAngular to match font sizes between WebSignup and NCApp3
  */
  --rem-base: 1.6;

  --color-white: #{$white};
  --color-black: #{$black};
  --color-purple: #{$brandPurple};
  --color-dark-purple: #{$brandPurple};
  --color-light-pink: #{$brandPink};
  --color-pink: #{$brandSalmon};
  --color-light-blue: #{$lightBlue};
  --color-blue: #{$brandBlue};
  --color-accessible-blue: #{$digitalBlue};
  --color-vanilla-ice: #{$neutral300};
  --color-green: #{$green500};
  --color-yellow: #{$colorYellow};
  --color-red: #{$red1000};
  --color-light-grey: #{$brandGrey};
  --color-medium-grey: #{$neutral200};
  --color-dark-grey: #{$grey700};
  --color-medium-dark-grey: #{$grey200};
  --color-border-grey: #{$colorBorderGrey};
  --color-oura-blue: #{$colorOuraBlue};
  --color-use-protectction-pink: #{$colorUseProtectionPink};
  --color-digital-blue: #{$digitalBlue};
  --color-brand-blue: #{$brandBlue};

  // ab-312
  --color-shimmer-gradient: #{$colorShimmerGradient};
}

// Quiz
$verticalSpacing: 32px;
$narrowVerticalSpacing: 16px;
$horizontalSpacing: 16px;
$containerWidth: 726px;
$containerSmallWidth: 538px;
$containerNarrowWidth: 348px;

// General
$navbarHeight: 60px;
