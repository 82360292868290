@import '_var';
@import '_typography';

h1,
h3 {
  @include title-l;
  margin-bottom: 24px;
  font-weight: 600;
}

a {
  color: $brandBlue;
}

table {
  width: 100%;
  border: 1px solid $brandPurple;
  margin-bottom: 15px;
  td,
  th {
    padding: 8px 16px;
    border-bottom: 1px solid $brandPurple;
    border-right: 1px solid $brandPurple;
  }
}
